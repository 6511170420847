<template>
  <div class='asset-sidebar__group asset-sidebar__group--photo-manager' @keydown.esc="close">
    <header>
      <h2>Quick Notes</h2>
      <a href='#' v-if='!isShown' @click.prevent.stop="show"><i class='fas fa-edit'></i> Edit</a>
      <a href='#' v-if='isShown' @click.prevent.stop="cancel">Cancel Editing</a>
    </header>
    <template v-if="isToggled">
      <div v-if='!isShown' class='rich_text_content' v-html='notes'></div>
      <div class='quick_notes__writer' v-if='isShown'>
        <slot></slot>
      </div>
    </template>
  </div>
</template>

<script>
import Writer from './writer.vue';

export default {
  props: ["company_url", "prenotes", "can"],
  components: {
    Writer
  },
  methods: {
    cancel () {
      document.location.reload()
    },
    toggle () {
      this.isToggled = !this.isToggled;
    },
    show () {
      this.isShown = true;
    },
    save () {
      var that = this;

      that.$api.put(that.company_url, {
        company: {
          notes: that.notes
        }
      })
      .then(function (response) {
        document.location.reload()
      })
    }
  },
  data () {
    return {
      notes: this.prenotes || "",
      isShown: false,
      isToggled: true
    }
  }
}
</script>

